import { render, staticRenderFns } from "./MembersOverview.vue?vue&type=template&id=2937ac2d&scoped=true"
import script from "./MembersOverview.vue?vue&type=script&lang=js"
export * from "./MembersOverview.vue?vue&type=script&lang=js"
import style0 from "./MembersOverview.vue?vue&type=style&index=0&id=2937ac2d&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2937ac2d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Container: require('/workspace/app/components/layout/Container.vue').default,MemberCard: require('/workspace/app/components/cards/MemberCard.vue').default,PillButton: require('/workspace/app/components/buttons/PillButton.vue').default,Module: require('/workspace/app/components/layout/Module.vue').default})

import { render, staticRenderFns } from "./MemberCard.vue?vue&type=template&id=1cdf5880"
import script from "./MemberCard.vue?vue&type=script&lang=js"
export * from "./MemberCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Visual: require('/workspace/app/components/media/Visual.vue').default,IconButton: require('/workspace/app/components/buttons/IconButton.vue').default})

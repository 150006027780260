
import VueTypes from 'vue-types';

import membersQuery from '@/gql/queries/sections/members.gql';

export default {
  inheritAttrs: false,
  props: {
    heading: VueTypes.string,
    description: VueTypes.string,
    locationEntry: VueTypes.arrayOf({
      uri: VueTypes.string,
    }),
    showAll: VueTypes.bool.def(false),
  },
  data() {
    return {
      members: [],
      randomMembers: [],
    };
  },
  async fetch() {
    const { members, randomMembers } = await this.$cms.query({
      query: membersQuery,
      variables: {
        site: this.$site,
      },
    });
    this.members = members;
    this.randomMembers = randomMembers;
  },
};
